.flexDisplay {
  display: flex;
}

.positionRelative {
  position: relative;
}

.top18 {
  top: '18px';
}

.flexwrap {
  display: flex;
  flex-wrap: wrap;
}

.cardStyle {
  background-color: '#f8f6fa';
  border: '2px solid #333';
}

.padding8 {
  padding: '8px';
}

.flexGrow1 {
  flex-grow: 1;
}

.minWidth156 {
  min-width: '156px';
}

.widthPer100 {
  width: 100%;
}

.FormHelperText {
  color: red;
}

.KeyboardDatePicker {
  min-width: '32%';
  padding-left: '0px';
}
